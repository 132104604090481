//! some code is commented for testing purpose
import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, DatePicker, Empty, Form, Input, message, Upload } from 'antd';
import {
  debounce,
  filter,
  includes,
  isArray,
  isNumber,
  map,
  take,
} from 'lodash';
import { evaluate } from 'mathjs';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AppContext } from '../../../../../../AppContext';
import {
  DeleteButton,
  DragIcon,
  EditButton,
} from '../../../../../../assets/svg';
import {
  ALLOWED_IMAGE_TYPES,
  DEFAULTDATEFORMAT,
  FALLBACK_PDF_URL,
  FIELD_TYPES,
  FILE_SIZE_5MB,
  REGEX,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import {
  fileUpload,
  formValidatorRules,
  getUniqueImages,
  toCamelCase,
} from '../../../../../../common/utils';
import CommonSelect from '../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../components/CommonTable';
import CommonTooltip from '../../../../../../components/CommonTooltip';
import { GET_URLS } from '../../../../../approver/graphql/Queries';
import {
  CREATE_REGISTER_TABLE_FIELDS,
  UPDATE_REGISTER_FORM_FIELD_SEQUENCES,
  UPDATE_REGISTER_LOG_DETAIL_ENTRY,
} from '../../../../graphql/Mutation';
import {
  GET_REGISTER,
  GET_REGISTER_LOG_FORM_FIELDS_ENTRY_LIST,
} from '../../../../graphql/Queries';
import AddFieldModal from './AddFieldModal';
import DeleteFormFieldRecordModal from './DeleteFormFieldRecordModal';
import DeleteTableFieldModal from './registerDetails/DeleteTableFieldModal';

const MyDatePicker = DatePicker.generatePicker(momentGenerateConfig);

const ColumnContext = React.createContext({});
const Columns = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props?.column?.id || props?.dataIndex,
  });

  const style = {
    ...props?.style,
    transform: CSS.Translate?.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <ColumnContext.Provider value={contextValue}>
      <th {...props} ref={setNodeRef} style={style} {...attributes} />
    </ColumnContext.Provider>
  );
};

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(ColumnContext);
  return (
    <CommonTooltip title="Change order">
      <DragIcon
        style={{
          cursor: 'move',
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    </CommonTooltip>
  );
};

const AddRegisterFieldsTable = React.memo(
  ({
    createRegisterData,
    tableHeaders,
    setTableHeaders,
    tableColumns,
    setTableColumns,
    isEditable,
  }) => {
    const {
      location,
      params: { projectId, registerId, registerLogId },
    } = useRouter();
    const { dispatch } = useContext(AppContext);
    const initialPagination = {
      skip: 0,
      limit: 20,
    };
    const { characterWithoutWhiteSpace } = formValidatorRules;
    const [form] = Form.useForm();
    const [numericFields, setNumericFields] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const fieldsData = useRef([]);
    const [selectedField, setSelectedField] = useState(false);
    const [showDeleteRecordModal, setShowDeleteRecordModal] = useState(false);
    const [selectedRecordToDelete, setSelectedRecordToDelete] = useState();
    const imageKeys = useRef([]);
    const tempUploadDataRef = useRef();
    const [deletingFile, setDeletingFile] = useState(false);
    const fileListRef = useRef({});
    const [uploadKey, setUploadKey] = useState(0);
    const [isCreatingEntry, setIsCreatingEntry] = useState(false);
    const createEntryTimeoutRef = useRef(null);
    const [createdRowIndexes, setCreatedRowIndexes] = useState(new Set());

    const showRouterPrompt = (value) => {
      dispatch({
        type: 'SET_SHOW_PROMPT',
        data: value,
      });
    };

    const calculateFieldValue = (fieldName) => {
      const [rowIndex, name] = fieldName?.split('_');
      const values = form?.getFieldsValue();
      const formulas = tableHeaders
        ?.filter((item) => item?.index === name)
        ?.flatMap((item) => item?.options || [])
        ?.map((str) => str?.replace(/"/g, ''))?.[0];
      if (!formulas) return '';
      try {
        const modifiedExpValue = formulas?.replace(REGEX.UUID, (match) => {
          return values?.[
            `${rowIndex}_${
              filter(tableHeaders, (record) => match === record?.id)?.[0]?.index
            }`
          ];
        });
        const result = evaluate(modifiedExpValue);
        return isNumber(result) ? Number(result)?.toFixed(2) : '';
      } catch (error) {
        return '';
      }
    };
    const formatNumericInput = (value) => {
      if (!value) return '';
      const numericValue = value?.replace(/[^\d.-]/g, '');
      const parts = numericValue?.split('.');
      if (parts?.length > 2) {
        return `${parts[0]}.${parts[1]}`;
      }
      if (parts?.length === 2) {
        const integerPart = parts[0];
        const decimalPart = parts[1]?.slice(0, 2);
        if (integerPart?.startsWith('-')) {
          return `${integerPart}.${decimalPart}`;
        }
        return `${integerPart}.${decimalPart}`;
      }
      return numericValue;
    };

    useEffect(() => {
      return () => {
        form.resetFields();
      };
    }, []);

    const [getRegisterLogFormFieldsEntryList] = useLazyQuery(
      GET_REGISTER_LOG_FORM_FIELDS_ENTRY_LIST,
      {
        fetchPolicy: 'network-only',
        onError() {},
        onCompleted(response) {
          setCreatedRowIndexes(new Set());
          fileListRef.current = {};
          setUploadKey((prev) => prev + 1);
          const transformedData =
            response?.registerLogFormFieldEntryList?.data?.map((item) => {
              const transformedItem = {
                id: item?.id || '',
                ...Object.entries(item?.responses || {})?.reduce(
                  (acc, [key, value]) => {
                    const field = tableColumns?.find(
                      (col) => col?.dataIndex === toCamelCase(key),
                    );
                    if (field?.type === FIELD_TYPES.DATE) {
                      if (value) {
                        acc[toCamelCase(key)] = moment(
                          value,
                          DEFAULTDATEFORMAT,
                        )?.isValid()
                          ? moment(value, DEFAULTDATEFORMAT)
                          : null;
                      } else {
                        acc[toCamelCase(key)] = null;
                      }
                    } else if (field?.type === FIELD_TYPES.CHECKBOXES) {
                      const values = value
                        ? value.split(', ').filter(Boolean)
                        : [];
                      acc[toCamelCase(key)] =
                        values.length > 0 ? values : undefined;
                    } else if (field?.type === FIELD_TYPES.OPTIONS) {
                      acc[toCamelCase(key)] = value || undefined;
                    } else {
                      acc[toCamelCase(key)] = value;
                    }
                    return acc;
                  },
                  {},
                ),
              };
              return transformedItem;
            }) || [];

          const newRow = tableHeaders?.reduce(
            (acc, field) => {
              acc[field?.dataIndex] = undefined;
              return acc;
            },
            { key: 'new', isEmptyRow: true },
          );

          const reversedData = [...transformedData]?.reverse();
          reversedData?.push(newRow);
          fieldsData.current = reversedData;
          setTableData(reversedData);
          reversedData.forEach((row, index) => {
            const formValues = {};
            tableHeaders.forEach((field) => {
              const fieldName = `${index}_${field.dataIndex}`;
              if (field?.type === FIELD_TYPES.CALCULATION) {
                formValues[fieldName] = calculateFieldValue(fieldName);
              } else if (
                field?.type === FIELD_TYPES.OPTIONS ||
                field?.type === FIELD_TYPES.CHECKBOXES
              ) {
                formValues[fieldName] = row[field?.dataIndex] || undefined;
              } else {
                formValues[fieldName] = row[field?.dataIndex];
              }
            });
            form.setFieldsValue(formValues);
          });

          const columns =
            response?.registerLogFormFieldEntryList?.registerFormFields || [];
          const filteredColumns = map(columns, (column) => {
            return {
              ...column,
              name: column?.name?.toUpperCase(),
              dataIndex: toCamelCase(column?.name),
              index: toCamelCase(column?.name),
            };
          });
          setTableHeaders(filteredColumns);
        },
      },
    );

    const [getRegister] = useLazyQuery(GET_REGISTER, {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted(response) {
        const data = response?.getRegister?.registerFormFields;
        const filteredColumns = map(data, (column) => ({
          ...column,
          dataIndex: toCamelCase(column?.name),
          index: toCamelCase(column?.name),
        }));
        setTableHeaders(filteredColumns);
      },
    });

    const [createRegisterLogFormFieldEntry] = useMutation(
      CREATE_REGISTER_TABLE_FIELDS,
      {
        fetchPolicy: 'network-only',
        onError() {},
        onCompleted() {
          showRouterPrompt(false);
          // const filteredTableData = [...tableData]?.slice(0, -1);
          // const requiredFields = tableHeaders
          //   ?.filter((field) => field?.isRequired)
          //   ?.map((field) => field?.dataIndex);
          // const allRequiredFieldsValid = filteredTableData?.every((record) => {
          //   return requiredFields?.every((fieldName) => {
          //     const fieldValue = record[fieldName];
          //     return (
          //       fieldValue !== null &&
          //       fieldValue !== undefined &&
          //       fieldValue !== '' &&
          //       !(Array.isArray(fieldValue) && fieldValue.length === 0)
          //     );
          //   });
          // });
          // if (allRequiredFieldsValid) {
          //   const isError =
          //     filter(form?.getFieldsError(), (e) => e?.errors?.length > 0)
          //       ?.length > 0;
          //   if (!isError) {
          //     getRegisterLogFormFieldsEntryList({
          //       variables: { registerLogId, filter: initialPagination },
          //     });
          //   }
          // }
        },
      },
    );

    const handleRegisterRefetch = () => {
      getRegister({ variables: { getRegisterId: registerId } });
    };

    const [updateRegisterFormFieldSequences] = useMutation(
      UPDATE_REGISTER_FORM_FIELD_SEQUENCES,
      {
        onError() {},
        onCompleted: () => {
          if (
            location?.pathname !==
            `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit-control`
          ) {
            getRegisterLogFormFieldsEntryList({
              variables: { registerLogId, filter: initialPagination },
            });
          }
          handleRegisterRefetch();
        },
      },
    );

    const handleRefetch = (type = '') => {
      if (type === 'delete') {
        getRegisterLogFormFieldsEntryList({
          variables: { registerLogId, filter: initialPagination },
        });
      } else {
        // const filteredTableData = [...tableData]?.slice(0, -1);
        // const requiredFields = tableHeaders
        //   ?.filter((field) => field?.isRequired)
        //   ?.map((field) => field?.dataIndex);
        // const allRequiredFieldsValid = filteredTableData?.every((record) => {
        //   return requiredFields?.every((fieldName) => {
        //     const fieldValue = record[fieldName];
        //     return (
        //       fieldValue !== null &&
        //       fieldValue !== undefined &&
        //       fieldValue !== '' &&
        //       !(Array.isArray(fieldValue) && fieldValue.length === 0)
        //     );
        //   });
        // });
        // if (allRequiredFieldsValid) {
        //   const isError =
        //     filter(form?.getFieldsError(), (e) => e?.errors?.length > 0)
        //       ?.length > 0;
        //   if (!isError) {
        //     getRegisterLogFormFieldsEntryList({
        //       variables: { registerLogId, filter: initialPagination },
        //     });
        //   }
        // }
      }
    };

    const [updateRegisterLogFormFieldEntry] = useMutation(
      UPDATE_REGISTER_LOG_DETAIL_ENTRY,
      {
        fetchPolicy: 'network-only',
        onError() {},
        onCompleted() {
          showRouterPrompt(false);
          // const filteredTableData = [...tableData]?.slice(0, -1);
          // const requiredFields = tableHeaders
          //   ?.filter((field) => field?.isRequired)
          //   ?.map((field) => field?.dataIndex);
          // const allRequiredFieldsValid = filteredTableData?.every((record) => {
          //   return requiredFields?.every((fieldName) => {
          //     const fieldValue = record[fieldName];
          //     return (
          //       fieldValue !== null &&
          //       fieldValue !== undefined &&
          //       fieldValue !== '' &&
          //       !(Array.isArray(fieldValue) && fieldValue.length === 0)
          //     );
          //   });
          // });
          // if (allRequiredFieldsValid) {
          //   const isError =
          //     filter(form?.getFieldsError(), (e) => e?.errors?.length > 0)
          //       ?.length > 0;
          //   if (!isError) {
          //     getRegisterLogFormFieldsEntryList({
          //       variables: { registerLogId, filter: initialPagination },
          //     });
          //   }
          // }
        },
      },
    );

    const mouseSensor = useSensor(MouseSensor);
    const touchSensor = useSensor(TouchSensor);
    const keyboardSensor = useSensor(KeyboardSensor);
    const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

    useEffect(() => {
      if (
        !createRegisterData &&
        location?.pathname !==
          `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit-control`
      ) {
        getRegisterLogFormFieldsEntryList({
          variables: { registerLogId, filter: initialPagination },
        });
      }
    }, [registerLogId]);

    const handleFieldChange = async (changedFields) => {
      const [fieldName, value] = Object?.entries(changedFields)?.[0];
      const [rowIndex, fieldType] = fieldName?.split('_');
      const changedField = tableHeaders?.find(
        (field) => field?.dataIndex === fieldType,
      );
      let formattedValue = value;
      if (changedField?.type === FIELD_TYPES.NUMERIC) {
        formattedValue = formatNumericInput(value);
      }
      form?.setFieldsValue({
        [fieldName]: formattedValue,
      });

      if (changedField?.type === FIELD_TYPES.NUMERIC) {
        const calculationFields = tableHeaders
          ?.filter((field) => field?.type === FIELD_TYPES.CALCULATION)
          ?.map((field) => field?.dataIndex);

        if (calculationFields?.length) {
          calculationFields?.forEach((calcField) => {
            const calcFieldName = `${rowIndex}_${calcField}`;
            const calculatedValue = calculateFieldValue(calcFieldName);
            form?.setFieldsValue({
              [calcFieldName]: calculatedValue,
            });
            if (fieldsData?.current?.[parseInt(rowIndex, 10)]) {
              fieldsData.current[parseInt(rowIndex, 10)][calcField] =
                calculatedValue;
            }
          });
        }
      }

      const updatedData = [...fieldsData?.current];
      const rowIndexNum = parseInt(rowIndex, 10);
      if (updatedData?.[rowIndexNum]) {
        updatedData[rowIndexNum] = {
          ...updatedData[rowIndexNum],
          [fieldType]: formattedValue,
        };

        const isLastRow = rowIndexNum === updatedData?.length - 1;
        if (isLastRow && formattedValue !== null && formattedValue !== '') {
          const newRow = tableHeaders?.reduce(
            (acc, field) => {
              acc[field?.dataIndex] = null;
              return acc;
            },
            { key: `new-${Date.now()}`, isEmptyRow: true },
          );
          updatedData?.push(newRow);
        }
        fieldsData.current = updatedData;
        setTableData(updatedData);
      }
    };

    const getDateData = (column, value, fieldName) => {
      let fieldValue = value;

      if (fieldValue) {
        if (moment(fieldValue)?.isValid()) {
          fieldValue = moment(fieldValue)?.format(DEFAULTDATEFORMAT);
        } else {
          form.setFields([
            {
              name: fieldName,
              errors: ['Please enter a valid date'],
            },
          ]);
          return 'Error';
        }
      } else if (column?.isRequired) {
        form.setFields([
          {
            name: fieldName,
            errors: ['This field is required'],
          },
        ]);
        return 'Error';
      }
      return fieldValue;
    };

    const getCheckBoxesData = (column, value, fieldName) => {
      let fieldValue = value;
      if (column?.type === FIELD_TYPES.CHECKBOXES) {
        if (
          !fieldValue ||
          (Array.isArray(fieldValue) && fieldValue.length === 0)
        ) {
          return '';
        }
        if (fieldValue) {
          fieldValue = isArray(fieldValue)
            ? fieldValue?.filter(Boolean)?.join(', ')
            : fieldValue;
        }
      } else if (column?.type === FIELD_TYPES.OPTIONS) {
        if (!fieldValue) {
          return '';
        }
      }

      if (column?.type === FIELD_TYPES.DATE) {
        const data = getDateData(column, fieldValue, fieldName);
        if (data === 'Error') {
          return 'Error';
        }
        fieldValue = data;
      }

      if (column?.type === FIELD_TYPES.ATTACHMENT) {
        const [rowIndex, name] = fieldName?.split('_');
        const currentData = fieldsData?.current?.[rowIndex]?.[name];
        let updatedKeys = [];
        if (Array.isArray(currentData)) {
          updatedKeys =
            currentData
              ?.filter((item) => item?.key)
              ?.map((item) => item?.key) || [];
        } else if (typeof currentData === 'string') {
          updatedKeys = currentData?.split(',')?.filter(Boolean);
        }

        const localImages = imageKeys?.current[fieldName] || [];
        fieldValue = getUniqueImages([...updatedKeys, ...localImages]);
        fieldValue = take(fieldValue, 3)?.join(',');
      }
      return fieldValue;
    };

    const createResponsesForEntry = (index, allValues, record) => {
      const responses = [];
      tableHeaders?.forEach((column) => {
        const entryFieldName = `${index}_${column?.dataIndex}`;
        let fieldValue = allValues[entryFieldName];

        if (column?.type === FIELD_TYPES.ATTACHMENT && record?.id) {
          const attachmentData = record[column?.dataIndex];
          if (Array.isArray(attachmentData)) {
            const currentFileList = fileListRef.current[entryFieldName] || [];
            const fileKeys = currentFileList
              .filter((item) => item?.key || item?.name || item?.url)
              .map((item) => {
                if (item?.key) return item?.key;
                if (item?.name) return `register/attachments/${item?.name}`;
                return item?.url;
              })
              .filter(Boolean);

            const localImages = imageKeys?.current[entryFieldName] || [];

            fieldValue = take(
              getUniqueImages([...fileKeys, ...localImages]),
              3,
            )?.join(',');
            responses?.push({
              registerFormFieldId: column?.id,
              value: fieldValue || '',
            });
            return;
          }
        }

        const data = getCheckBoxesData(column, fieldValue, entryFieldName);
        if (data === 'Error') {
          return 'Error';
        }
        fieldValue = data;

        if (column?.type === FIELD_TYPES.ATTACHMENT) {
          imageKeys.current[entryFieldName] = [];
        }

        if (record?.id) {
          responses?.push({
            registerFormFieldId: column?.id,
            value:
              fieldValue === null || fieldValue === undefined
                ? ''
                : String(fieldValue),
          });
        } else if (
          !column?.isRequired ||
          (fieldValue !== undefined && fieldValue !== null && fieldValue !== '')
        ) {
          responses?.push({
            registerFormFieldId: column?.id,
            value: Array.isArray(fieldValue)
              ? fieldValue
              : String(fieldValue || ''),
          });
        }
      });
      return responses;
    };

    const handleBlurSave = async (rowIndex, record, dataIndex) => {
      if (createEntryTimeoutRef.current) {
        clearTimeout(createEntryTimeoutRef.current);
      }
      createEntryTimeoutRef.current = setTimeout(async () => {
        const allValues = form.getFieldsValue();
        const currentFieldName = `${rowIndex}_${dataIndex}`;
        const currentFieldValue = allValues[currentFieldName];

        const currentColumn = tableColumns?.find(
          (col) => col?.dataIndex === dataIndex,
        );
        if (currentColumn?.type === FIELD_TYPES.DATE && record?.id) {
          return;
        }
        if (
          currentColumn?.type === FIELD_TYPES.NUMERIC &&
          currentColumn?.isRequired
        ) {
          const numericValue = Number(currentFieldValue);
          if (Number?.isNaN(numericValue) || currentFieldValue === '') {
            form.setFields([
              {
                name: currentFieldName,
                errors: ['Please enter a valid number'],
              },
            ]);
            return;
          }
        }

        const thisRow = fieldsData.current[rowIndex];
        const hasValidId =
          thisRow?.id && !String(thisRow.key).startsWith('new');

        if (record?.isEmptyRow && createdRowIndexes.has(rowIndex)) {
          return;
        }

        if (!hasValidId) {
          const requiredFields = tableHeaders?.filter(
            (field) => field?.isRequired,
          );
          const allRequiredFieldsFilled = requiredFields?.every((column) => {
            const fieldName = `${rowIndex}_${column?.dataIndex}`;
            const fieldValue = allValues[fieldName];

            if (column?.type === FIELD_TYPES.CALCULATION) {
              return true;
            }

            if (Array.isArray(fieldValue)) {
              return fieldValue.length > 0;
            }
            if (column?.type === FIELD_TYPES.NUMERIC) {
              const numValue = Number(fieldValue);
              return (
                !Number.isNaN(numValue) &&
                fieldValue !== '' &&
                fieldValue !== null &&
                fieldValue !== undefined
              );
            }
            return (
              fieldValue !== null &&
              fieldValue !== undefined &&
              fieldValue !== ''
            );
          });

          if (!allRequiredFieldsFilled) {
            if (
              currentColumn?.isRequired &&
              (!currentFieldValue || currentFieldValue === '')
            ) {
              form.setFields([
                {
                  name: currentFieldName,
                  errors: ['This field is required'],
                },
              ]);
            }
            return;
          }
        } else if (currentColumn?.isRequired) {
          if (!currentFieldValue || currentFieldValue === '') {
            form.setFields([
              {
                name: currentFieldName,
                errors: ['This field is required'],
              },
            ]);
            return;
          }
        }

        const responses = createResponsesForEntry(
          rowIndex,
          allValues,
          thisRow || record,
        );
        if (responses?.length > 0) {
          try {
            if (hasValidId) {
              await updateRegisterLogFormFieldEntry({
                variables: {
                  updateRegisterLogFormFieldEntryId: thisRow.id,
                  data: {
                    isNewRow: false,
                    responses,
                  },
                },
              });
            } else if (!isCreatingEntry) {
              setIsCreatingEntry(true);
              try {
                const result = await createRegisterLogFormFieldEntry({
                  variables: {
                    registerLogId,
                    data: {
                      filling: null,
                      tableEntries: responses,
                    },
                  },
                });

                if (result?.data?.createRegisterLogFormFieldEntry?.id) {
                  const newId = result.data.createRegisterLogFormFieldEntry.id;

                  const updatedData = [...fieldsData.current];
                  updatedData[rowIndex] = {
                    ...updatedData[rowIndex],
                    id: newId,
                    key: newId,
                    isEmptyRow: false,
                  };

                  fieldsData.current = updatedData;
                  setTableData(updatedData);
                  setCreatedRowIndexes((prev) => new Set([...prev, rowIndex]));
                }
              } finally {
                setIsCreatingEntry(false);
              }
            }
          } catch (error) {
            return error;
          }
        }
      }, 300);
    };

    const handleDeleteRecord = (record) => {
      setSelectedRecordToDelete(record);
      setShowDeleteRecordModal(true);
    };

    useEffect(() => {
      if (
        tableColumns?.length > 0 &&
        location?.pathname ===
          `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit-control`
      ) {
        const filteredColumns = map(tableColumns, (column) => ({
          ...column,
          dataIndex: toCamelCase(column?.name),
          index: toCamelCase(column?.name),
        }));
        setTableHeaders(filteredColumns);
      }
    }, [tableColumns]);

    useEffect(() => {
      const filteredFields = tableHeaders?.filter(
        (field) => field?.type === FIELD_TYPES.NUMERIC,
      );
      setNumericFields(filteredFields);
    }, [tableHeaders]);

    useEffect(() => {
      const values = form.getFieldsValue();
      const formFields = Object.keys(values);
      const calculationFields = tableHeaders
        ?.filter((field) => field?.type === FIELD_TYPES.CALCULATION)
        ?.map((field) => field?.dataIndex);

      if (calculationFields?.length) {
        formFields.forEach((fieldName) => {
          const [, fieldType] = fieldName.split('_');
          if (calculationFields.includes(fieldType)) {
            const calculatedValue = calculateFieldValue(fieldName);
            form.setFieldsValue({
              [fieldName]: calculatedValue,
            });
          }
        });
      }
    }, [form.getFieldsValue(), tableHeaders]);

    const [fetchSignedUrl] = useLazyQuery(GET_URLS, {
      fetchPolicy: 'network-only',
      onCompleted: async (response) => {
        const promises = map(
          response?.getSignedPutUrls?.signedUrls,
          async (imageUrl, index) => {
            const objectFiles = tempUploadDataRef?.current?.filter(
              (item) => item?.thumbUrl,
            );
            await fileUpload(imageUrl, objectFiles[index]?.originFileObj).catch(
              () => {
                return message.error('Image upload failed!');
              },
            );
          },
        );
        await Promise.all(promises);
      },
      onError() {
        message.error('Error getting upload URL');
      },
    });
    const handleUploadImage = async (
      newFileList,
      fieldName,
      record,
      dataIndex,
      index,
    ) => {
      if (createEntryTimeoutRef?.current) {
        clearTimeout(createEntryTimeoutRef.current);
      }
      const limitedFileList = newFileList?.slice(-3);

      const fileSend = (anotherFileList) => {
        return map(anotherFileList, (file) => {
          const fileType = file?.originFileObj?.type || file?.type;
          const fileName = file?.originFileObj?.name || file?.name;

          return {
            fileName: `register/attachments/${fileName}`,
            contentType: fileType,
          };
        });
      };

      const filteredFileList = filter(
        limitedFileList,
        (file) => file?.originFileObj?.name || file?.name,
      );

      if (filteredFileList?.length) {
        try {
          fetchSignedUrl({
            variables: {
              data: fileSend(filteredFileList),
            },
          })?.then(({ data }) => {
            const keys = data?.getSignedPutUrls?.keys || [];

            if (fileListRef?.current?.[fieldName] && keys?.length > 0) {
              const updatedFileList = [...fileListRef?.current[fieldName]];

              filteredFileList?.forEach((file, i) => {
                if (keys[i]) {
                  const fileIndex = updatedFileList?.findIndex(
                    (f) => f?.uid === file?.uid,
                  );
                  if (fileIndex >= 0) {
                    updatedFileList[fileIndex] = {
                      ...updatedFileList[fileIndex],
                      key: keys[i],
                    };
                  }
                }
              });

              fileListRef.current[fieldName] = updatedFileList;

              if (fieldsData?.current?.[index]) {
                fieldsData.current[index] = {
                  ...fieldsData.current[index],
                  [dataIndex]: updatedFileList?.map((file) => ({
                    uid: file?.uid,
                    status: file?.status,
                    url: file?.url,
                    value: file?.url,
                    key: file?.key,
                    name: file?.name,
                    thumbUrl: file?.thumbUrl,
                  })),
                };
              }
            }

            const uploadedKeys = imageKeys?.current?.[fieldName];
            if (uploadedKeys) {
              imageKeys.current[fieldName] = getUniqueImages([
                ...uploadedKeys,
                ...keys,
              ]);
            } else {
              imageKeys.current[fieldName] = getUniqueImages([...keys]);
            }
            const key = `${index}_${dataIndex}`;
            form?.setFieldsValue({
              [key]: keys,
            });
            handleBlurSave(index, record, dataIndex);
          });
        } catch (error) {
          return error;
        }
      }
    };

    const handleOnChange = debounce(
      (info, fieldName, record, dataIndex, index) => {
        const newFileList = info?.fileList?.filter((file) => {
          const fileType = file?.originFileObj?.type || file?.type;

          if (
            !file?.url &&
            fileType &&
            !includes([...ALLOWED_IMAGE_TYPES, 'application/pdf'], fileType)
          ) {
            message?.destroy();
            message?.error('File type should be PNG, JPG, JPEG,PDF');
            return false;
          }

          return true;
        });
        handleFieldChange({ [fieldName]: newFileList });
        tempUploadDataRef.current = newFileList;
        handleUploadImage(newFileList, fieldName, record, dataIndex, index);
      },
      300,
    );

    const handlePreview = async (file) => {
      let previewUrl = file?.url;
      if (file?.originFileObj) {
        previewUrl = URL.createObjectURL(file.originFileObj);
      } else if (file?.thumbUrl && !file?.url) {
        previewUrl = file.thumbUrl;
      }

      if (previewUrl) {
        // eslint-disable-next-line no-undef
        window?.open(previewUrl, '_blank');
        if (file?.originFileObj) {
          URL.revokeObjectURL(previewUrl);
        }
      }
    };

    const UploadComponent = React.memo(
      ({ fieldName, record, dataIndex, index }) => {
        if (!fileListRef?.current?.[fieldName]) {
          const initialFiles = Array.isArray(record?.[dataIndex])
            ? record[dataIndex]?.slice(-3)?.map((item, idx) => ({
                uid: `${fieldName}-${idx}`,
                status: 'done',
                url: item?.value || item?.url,
                key: item?.key,
                name:
                  item?.name ||
                  (item?.key ? item?.key.split('/').pop() : undefined),
                thumbUrl:
                  item?.key?.split('.')?.[1] === 'pdf'
                    ? FALLBACK_PDF_URL
                    : item?.value || item?.url,
              }))
            : [];
          fileListRef.current[fieldName] = initialFiles;
        } else {
          fileListRef.current[fieldName] =
            fileListRef.current[fieldName].slice(-3);
        }

        const handleChange = useCallback(
          (info) => {
            const isRemoveOperation =
              info.fileList.length < fileListRef.current[fieldName]?.length;

            if (isRemoveOperation) {
              const newFileList = info.fileList.map((file) => ({
                ...file,
                status: 'done',
              }));
              fileListRef.current[fieldName] = newFileList;

              if (fieldsData?.current?.[index]) {
                fieldsData.current[index] = {
                  ...fieldsData.current[index],
                  [dataIndex]: newFileList?.map((file) => ({
                    uid: file?.uid,
                    status: file?.status,
                    url: file?.url,
                    value: file?.url,
                    key: file?.key,
                    name: file?.name,
                    thumbUrl: file?.thumbUrl,
                  })),
                };
              }
              form?.setFieldsValue({
                [fieldName]: newFileList,
              });
              return;
            }
            const newFileList = info.fileList.slice(-3).map((file) => ({
              ...file,
              status: 'done',
            }));
            if (newFileList.length > 3) {
              message.warning('Maximum 3 files are allowed');
              return;
            }
            fileListRef.current[fieldName] = newFileList;

            if (fieldsData?.current?.[index]) {
              fieldsData.current[index] = {
                ...fieldsData.current[index],
                [dataIndex]: newFileList?.map((file) => ({
                  uid: file?.uid,
                  status: file?.status,
                  url: file?.url,
                  value: file?.url,
                  key:
                    file?.key ||
                    (file?.name
                      ? `register/attachments/${file?.name}`
                      : undefined),
                  name: file?.name,
                  thumbUrl: file?.thumbUrl,
                })),
              };
            }

            const hasNewFiles = newFileList.some(
              (file) => !file.url && !file.key,
            );
            if (hasNewFiles) {
              handleOnChange(info, fieldName, record, dataIndex, index);
            }
          },
          [fieldName, record, dataIndex, index],
        );

        return (
          <div>
            <Upload
              key={`${uploadKey}-${fieldName}`}
              maxCount={3}
              accept=".png,.jpg,.jpeg,.pdf"
              multiple
              listType="picture-card"
              className="register-attachment"
              fileList={fileListRef.current[fieldName]?.slice(-3)}
              onChange={handleChange}
              showUploadList={{
                showPreviewIcon: true,
                showRemoveIcon: true,
                showDownloadIcon: false,
              }}
              beforeUpload={(file) => {
                const currentFiles = fileListRef.current[fieldName] || [];
                if (currentFiles.length >= 3) {
                  message.warning('Maximum 3 files are allowed');
                  return Upload.LIST_IGNORE;
                }
                if (file?.size > FILE_SIZE_5MB) {
                  message.destroy();
                  message.error('Maximum file size is 5MB');
                  return Upload.LIST_IGNORE;
                }
                const newFile = file;
                if (file?.type?.startsWith('image/')) {
                  newFile.thumbUrl = URL?.createObjectURL(file);
                } else if (file?.type === 'application/pdf') {
                  newFile.thumbUrl = FALLBACK_PDF_URL;
                }
                return true;
              }}
              onRemove={async (file) => {
                try {
                  if (deletingFile) return false;
                  setDeletingFile(true);
                  const currentFileList = [
                    ...(fileListRef?.current[fieldName] || []),
                  ];
                  const updatedFileList = currentFileList?.filter(
                    (f) => f?.uid !== file?.uid,
                  );
                  fileListRef.current[fieldName] = updatedFileList;
                  const currentField = fieldsData?.current?.[index];
                  if (!currentField) {
                    setDeletingFile(false);
                    return true;
                  }
                  const currentFiles = currentField[dataIndex] || [];
                  const remainingFiles = currentFiles.filter((item) => {
                    if (file?.thumbUrl) {
                      return item?.thumbUrl !== file?.thumbUrl;
                    }
                    return item?.key !== file?.key && item?.value !== file?.url;
                  });
                  fieldsData.current[index] = {
                    ...currentField,
                    [dataIndex]: remainingFiles,
                  };
                  form.setFieldValue(fieldName, remainingFiles);
                  if (file?.name && imageKeys?.current?.[fieldName]) {
                    const fileNameWithoutSequence = file?.name?.replace(
                      /_(\(\d+\))_\d+/,
                      ' $1',
                    );
                    imageKeys.current[fieldName] = imageKeys?.current?.[
                      fieldName
                    ]?.filter(
                      (key) =>
                        key?.replace(/_(\(\d+\))_\d+/, ' $1') !==
                        fileNameWithoutSequence,
                    );
                  }
                  if (record?.id) {
                    const currentColumn = tableHeaders?.find(
                      (col) => col?.dataIndex === dataIndex,
                    );
                    if (currentColumn) {
                      const existingKeys = currentFiles
                        ?.filter((f) => f?.key && f?.key !== file?.key)
                        ?.map((f) => f?.key);
                      const newKeys = imageKeys.current[fieldName] || [];
                      const allFileKeys = [
                        ...new Set([...existingKeys, ...newKeys]),
                      ];
                      const finalFileKeys = allFileKeys.filter(
                        (key) =>
                          key !== file?.key &&
                          (!file?.name || !key?.includes(file?.name)),
                      );

                      await updateRegisterLogFormFieldEntry({
                        variables: {
                          updateRegisterLogFormFieldEntryId: record.id,
                          data: {
                            isNewRow: false,
                            responses: [
                              {
                                registerFormFieldId: currentColumn.id,
                                value: take(
                                  getUniqueImages(finalFileKeys),
                                  3,
                                )?.join(','),
                              },
                            ],
                          },
                        },
                      });
                    }
                  }

                  setDeletingFile(false);
                  setUploadKey((prev) => prev + 1);
                  return true;
                } catch (error) {
                  message.error('Failed to remove file');
                  setDeletingFile(false);
                  return false;
                }
              }}
              onPreview={handlePreview}
            >
              {(!fileListRef.current[fieldName] ||
                fileListRef.current[fieldName]?.length < 3) && (
                <div className="height-percent-100 width-percent-100 d-flex align-center justify-center pointer">
                  <Button className="upload-attachment-button" type="link">
                    Upload
                  </Button>
                </div>
              )}
            </Upload>
          </div>
        );
      },
    );

    const renderEditableCell = (
      field,
      dataIndex,
      title,
      inputType,
      record,
      index,
      options,
    ) => {
      const fieldOptions = map(options, (option) => ({
        label: option,
        value: option,
      }));

      const fieldName = `${index}_${dataIndex}`;
      switch (inputType) {
        case FIELD_TYPES.TEXT:
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={record[dataIndex]}
                rules={[
                  {
                    required: field?.isRequired && !record?.isEmptyRow,
                    message: `This field is required!`,
                  },
                  characterWithoutWhiteSpace,
                ]}
              >
                <Input
                  placeholder="Enter the text"
                  onChange={(e) =>
                    handleFieldChange({ [fieldName]: e?.target?.value }, index)
                  }
                  onBlur={() => handleBlurSave(index, record, dataIndex)}
                />
              </Form.Item>
            </div>
          );

        case FIELD_TYPES.MULTI_LINE_TEXT:
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={record[dataIndex]}
                rules={[
                  {
                    required: field?.isRequired && !record?.isEmptyRow,
                    message: 'This field is required!',
                  },
                  {
                    max: 350,
                    message: 'Maximum 350 characters are allowed',
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter the text"
                  rows={4}
                  onChange={(e) =>
                    handleFieldChange({ [fieldName]: e?.target?.value })
                  }
                  onBlur={() => handleBlurSave(index, record, dataIndex)}
                />
              </Form.Item>
            </div>
          );

        case FIELD_TYPES.NUMERIC:
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={record[dataIndex]}
                rules={[
                  {
                    required: field?.isRequired && !record?.isEmptyRow,
                    message: 'This field is required!',
                  },
                  {
                    max: 15,
                    message: 'Maximum 15 digits are allowed',
                  },
                  {
                    validator: (_, value) => {
                      if (!value || value === '') {
                        return Promise.resolve();
                      }
                      const numericValue = Number(value);
                      if (Number.isNaN(numericValue)) {
                        return Promise.reject(
                          new Error('Please enter a valid number'),
                        );
                      }
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter the number"
                  inputMode="decimal"
                  pattern="[-]?[0-9]*[.,]?[0-9]*"
                  onChange={(e) => {
                    const value = e?.target?.value;
                    const formattedValue = formatNumericInput(value);
                    handleFieldChange({ [fieldName]: formattedValue }, index);
                  }}
                  onBlur={() => {
                    const allValues = form.getFieldsValue();
                    const requiredColumns = tableHeaders?.filter(
                      (col) => col?.isRequired,
                    );
                    const currentValue = form.getFieldValue(fieldName);
                    if (
                      field?.isRequired &&
                      (!currentValue || currentValue === '')
                    ) {
                      form.setFields([
                        {
                          name: fieldName,
                          errors: ['This field is required'],
                        },
                      ]);
                      return;
                    }

                    if (currentValue) {
                      const numericValue = Number(currentValue);
                      if (Number.isNaN(numericValue)) {
                        form.setFields([
                          {
                            name: fieldName,
                            errors: ['Please enter a valid number'],
                          },
                        ]);
                        return;
                      }
                    }
                    const invalidFields = [];
                    requiredColumns?.forEach((column) => {
                      const requiredFieldName = `${index}_${column?.dataIndex}`;
                      const fieldValue = allValues[requiredFieldName];

                      if (
                        fieldValue === null ||
                        fieldValue === '' ||
                        fieldValue === undefined
                      ) {
                        invalidFields.push({
                          name: requiredFieldName,
                          errors: ['This field is required'],
                        });
                      } else if (column?.type === FIELD_TYPES.NUMERIC) {
                        const numValue = Number(fieldValue);
                        if (Number.isNaN(numValue)) {
                          invalidFields.push({
                            name: requiredFieldName,
                            errors: ['Please enter a valid number'],
                          });
                        }
                      }
                    });

                    if (invalidFields.length > 0) {
                      form.setFields(invalidFields);
                      return;
                    }
                    if (!record?.isEmptyRow || !field?.isRequired) {
                      handleBlurSave(index, record, dataIndex);
                    } else {
                      const allRequiredFieldsValid = requiredColumns?.every(
                        (column) => {
                          const requiredFieldName = `${index}_${column?.dataIndex}`;
                          const fieldValue = allValues[requiredFieldName];
                          if (
                            fieldValue === null ||
                            fieldValue === '' ||
                            fieldValue === undefined
                          ) {
                            return false;
                          }
                          if (column?.type === FIELD_TYPES.NUMERIC) {
                            const numValue = Number(fieldValue);
                            return !Number.isNaN(numValue);
                          }
                          return true;
                        },
                      );
                      if (allRequiredFieldsValid) {
                        handleBlurSave(index, record, dataIndex);
                      }
                    }
                  }}
                />
              </Form.Item>
            </div>
          );

        case FIELD_TYPES.DATE:
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={
                  record[dataIndex] &&
                  moment(record[dataIndex], DEFAULTDATEFORMAT)
                }
                rules={[
                  {
                    required: field?.isRequired && !record?.isEmptyRow,
                    message: 'This field is required!',
                  },
                ]}
              >
                <MyDatePicker
                  className="width-percent-100"
                  onChange={async (date) => {
                    handleFieldChange({ [fieldName]: date }, index);
                    if (record.id) {
                      try {
                        const formattedDate = date
                          ? moment(date).format(DEFAULTDATEFORMAT)
                          : null;
                        await updateRegisterLogFormFieldEntry({
                          variables: {
                            updateRegisterLogFormFieldEntryId: record.id,
                            data: {
                              isNewRow: false,
                              responses: [
                                {
                                  registerFormFieldId: field.id,
                                  value: formattedDate || '',
                                },
                              ],
                            },
                          },
                        });
                      } catch (error) {
                        message.error('Failed to update date');
                      }
                    } else if (record?.isEmptyRow && !isCreatingEntry) {
                      const allValues = form.getFieldsValue();
                      const requiredColumns = tableHeaders?.filter(
                        (col) => col?.isRequired,
                      );
                      const hasEmptyRequiredFields = requiredColumns?.some(
                        (column) => {
                          const requiredFieldName = `${index}_${column?.dataIndex}`;
                          const fieldValue = allValues[requiredFieldName];
                          const isEmpty =
                            !fieldValue ||
                            (Array.isArray(fieldValue) &&
                              fieldValue?.length === 0) ||
                            fieldValue === '';

                          if (isEmpty) {
                            form.setFields([
                              {
                                name: requiredFieldName,
                                errors: ['This field is required'],
                              },
                            ]);
                            return true;
                          }
                          return false;
                        },
                      );

                      if (hasEmptyRequiredFields) {
                        return;
                      }
                      setIsCreatingEntry(true);
                      try {
                        const responses = createResponsesForEntry(
                          index,
                          allValues,
                          record,
                        );
                        await createRegisterLogFormFieldEntry({
                          variables: {
                            registerLogId,
                            data: {
                              filling: null,
                              tableEntries: responses,
                            },
                          },
                        });
                      } finally {
                        setIsCreatingEntry(false);
                      }
                    }
                  }}
                  onBlur={() => {
                    if (!record?.isEmptyRow) {
                      handleBlurSave(index, record, dataIndex);
                    }
                  }}
                  format={DEFAULTDATEFORMAT}
                />
              </Form.Item>
            </div>
          );

        case FIELD_TYPES.OPTIONS:
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={record[dataIndex] || undefined}
                rules={[
                  {
                    required: field?.isRequired && !record?.isEmptyRow,
                    message: 'This field is required!',
                  },
                  {
                    message: 'Please select an option!',
                  },
                ]}
              >
                <CommonSelect
                  allowClear
                  className="form-field-select"
                  placeholder="Select an option"
                  options={fieldOptions}
                  value={record[dataIndex] || undefined}
                  onChange={(value) => {
                    handleFieldChange({ [fieldName]: value || undefined });
                    handleBlurSave(index, record, dataIndex);
                  }}
                />
              </Form.Item>
            </div>
          );

        case FIELD_TYPES.CHECKBOXES:
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={record[dataIndex] || undefined}
                rules={[
                  {
                    required: field?.isRequired && !record?.isEmptyRow,
                    message: 'This field is required!',
                  },
                ]}
              >
                <CommonSelect
                  placeholder="Select an option"
                  mode="multiple"
                  options={fieldOptions}
                  value={record[dataIndex] || undefined}
                  onChange={(value) => {
                    const newValue = value?.length > 0 ? value : undefined;
                    handleFieldChange({ [fieldName]: newValue });
                    handleBlurSave(index, record, dataIndex);
                  }}
                />
              </Form.Item>
            </div>
          );

        case FIELD_TYPES.ATTACHMENT:
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={record[dataIndex]}
                rules={[
                  {
                    required: field?.isRequired && !record?.isEmptyRow,
                    message: 'This field is required!',
                  },
                ]}
              >
                <UploadComponent
                  fieldName={fieldName}
                  record={record}
                  dataIndex={dataIndex}
                  index={index}
                />
              </Form.Item>
            </div>
          );

        case FIELD_TYPES.CALCULATION: {
          const calculatedValue = calculateFieldValue(fieldName);
          return (
            <div className="width-200">
              <Form.Item
                className="m-0"
                name={fieldName}
                initialValue={calculatedValue}
              >
                <Input
                  disabled
                  placeholder="-"
                  value={calculatedValue || '-'}
                  onChange={(e) =>
                    handleFieldChange({ [fieldName]: e?.target?.value }, index)
                  }
                  onBlur={() => handleBlurSave(index, record, dataIndex)}
                />
              </Form.Item>
            </div>
          );
        }

        default:
          return null;
      }
    };

    const handleEditField = (field) => {
      setSelectedField(field);
      setShowModal(true);
    };

    const columns = useMemo(
      () => [
        ...(tableHeaders?.length > 0
          ? tableHeaders?.map((field) => ({
              ...field,
              dataIndex: field?.id,
              key: field?.id,
              id: field?.id,
              onHeaderCell: (column) => ({
                column,
                dataIndex: field?.dataIndex,
              }),
              render: (text, record, index) => {
                return !isEditable
                  ? renderEditableCell(
                      field,
                      field?.dataIndex,
                      field?.title,
                      field?.type,
                      record,
                      index,
                      field?.options,
                    )
                  : null;
              },
              title: (
                <div className="width-percent-100 d-flex align-center justify-between gap-8">
                  {isEditable && registerId && <DragHandle />}
                  <div className="d-flex">
                    <div className="mr-5">{field?.name?.toUpperCase()}</div>
                    {field?.isRequired && <div className="danger-text">*</div>}
                  </div>
                  {isEditable && (
                    <div className="d-flex">
                      <EditButton
                        className="mr-10"
                        onClick={() => handleEditField(field)}
                      />
                      <DeleteButton
                        onClick={() => {
                          setSelectedField(field);
                          setShowDeleteModal(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              ),
              className: isEditable ? 'drag-visible' : '',
            }))
          : []),
        ...(isEditable
          ? [
              {
                key: 'addField',
                id: 'addField',
                title: (
                  <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setSelectedField();
                      setShowModal(true);
                    }}
                  >
                    Add Field
                  </Button>
                ),
                dataIndex: 'addField',
                render: () => null,
                disableSort: true,
                onHeaderCell: () => ({}),
                className: '',
              },
            ]
          : [
              {
                key: 'actions',
                id: 'actions',
                dataIndex: 'action',
                title: 'ACTIONS',
                render: (_, record) => (
                  <div className="d-flex align-center width-percent-100">
                    <DeleteButton
                      className="pointer"
                      onClick={() => handleDeleteRecord(record)}
                    />
                  </div>
                ),
              },
            ]),
      ],
      [tableHeaders, isEditable],
    );

    const handleDragEnd = (event) => {
      const { active, over } = event;
      if (active && over) {
        updateRegisterFormFieldSequences({
          variables: {
            data: {
              registerId,
              registerFormFieldId: active?.id,
              sequence: over?.data?.current?.sortable?.index + 1,
            },
          },
        });
      }
      if (!active || !over || active?.id === over?.id) {
        return;
      }
      const oldIndex = tableHeaders?.findIndex((col) => col?.id === active?.id);
      const newIndex = tableHeaders?.findIndex((col) => col?.id === over?.id);
      if (oldIndex !== -1 && newIndex !== -1) {
        const newHeaders = arrayMove(tableHeaders, oldIndex, newIndex);
        setTableHeaders(newHeaders);
        const newTableColumns = arrayMove(tableColumns, oldIndex, newIndex);
        setTableColumns(newTableColumns);
      }
    };

    return (
      <div>
        <DndContext
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={tableHeaders?.map((col) => col?.id)}
            strategy={horizontalListSortingStrategy}
          >
            <div className="width-percent-100">
              <h3>Table</h3>
              {!tableHeaders?.length > 0 && !isEditable ? (
                <Empty />
              ) : (
                <Form
                  form={form}
                  component={false}
                  onFieldsChange={() => {
                    showRouterPrompt(true);
                  }}
                >
                  <CommonTable
                    components={{ header: { cell: Columns } }}
                    data={tableData}
                    columns={columns}
                    scroll={{ x: 'max-content' }}
                  />
                </Form>
              )}
            </div>
          </SortableContext>
        </DndContext>
        {showModal && (
          <AddFieldModal
            createRegisterData={createRegisterData}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            tableColumns={tableColumns}
            setTableColumns={setTableColumns}
            isEditable={isEditable}
            selectedField={selectedField}
            isUpdate={!!selectedField}
            numericFields={numericFields}
            setNumericFields={setNumericFields}
            showModal={showModal}
            setShowModal={setShowModal}
            setSelectedField={setSelectedField}
            handleRefetch={handleRegisterRefetch}
          />
        )}
        {showDeleteModal && (
          <DeleteTableFieldModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            tableHeaders={tableHeaders}
            setTableHeaders={setTableHeaders}
            handleRefetch={handleRegisterRefetch}
            createRegisterData={createRegisterData}
          />
        )}
        {showDeleteRecordModal && (
          <DeleteFormFieldRecordModal
            showModal={showDeleteRecordModal}
            setShowModal={setShowDeleteRecordModal}
            selectedRecordToDelete={selectedRecordToDelete}
            setSelectedRecordToDelete={setSelectedRecordToDelete}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
    );
  },
);

export default AddRegisterFieldsTable;
